<template>
  <v-btn
    class="item px-6 py-5 rounded-lg"
    color="white"
    block
    :class="{ 'item--inactive': disabled }"
    :ripple="{ class: 'item__ripple' }"
    :disabled="disabled"
    :href="href"
    target="_blank"
    @click="$emit('click', $event)"
  >
    <v-row align-content="center" no-gutters>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-icon v-if="icon" x-large class="item__icon">
          {{ `$vuetify.icons.${icon}` }}
        </v-icon>
        <slot v-else name="icon" />
      </v-col>
      <v-col :cols="disabled ? 8 : 10">
        <div class="px-6">
          <div :class="['item__title', { 'mb-1': $vuetify.breakpoint.smAndUp }]">
            {{ title }}
          </div>
          <div class="item__text">{{ description }}</div>
        </div>
      </v-col>
      <v-col class="d-flex align-center justify-end" :cols="disabled ? 3 : 1">
        <slot name="end">
          <div class="item__chevron">
            <v-icon color="info"> $vuetify.icons.arrow_right </v-icon>
          </div>
        </slot>
      </v-col>
    </v-row>
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    href: String,
    disabled: Boolean,
    icon: String,
  },
})
</script>

<style lang="sass" scoped>
.item
  +skribble-box-shadow
  text-align: left
  border: none !important
  height: min-content !important
  white-space: normal !important
  &:before
    display: none
  &--inactive
    box-shadow: none
  ::v-deep &__ripple
    color: $c-grey
  &__title
    font-size: 1.25rem
    font-weight: bold
    color: $c-skribbleu
    +standard-transition
    +maw(xs)
      font-size: 1.125rem
  &__text
    font-size: 1rem
    color: $c-grey-dark
    font-weight: normal
    +maw(xs)
      font-size: 0.875rem
  &__icon
    display: block
    opacity: 1 !important
    color: $c-grey-dark !important
  &__chevron
    display: block
    opacity: 1 !important
    color: $c-primary !important
  &:hover
    .item__title
      color: $c-primary
  &:active
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12)
  ::v-deep .v-btn__content
    flex: 1
</style>
