import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import DialogConnectIdAustria from '@/components/dialogs/DialogConnectIdAustria.vue';
import IdentificationMethodItem from '@/components/identification/IdentificationMethodItem.vue';
export default defineComponent({
  name: 'IdentificationMethods',
  components: {
    DialogConnectIdAustria: DialogConnectIdAustria,
    IdentificationMethodItem: IdentificationMethodItem
  },
  props: {
    availableMethods: {
      type: Array,
      default: function _default() {
        return ['video', 'self', 'auto', 'loc', 'id-austria'];
      }
    },
    isDirectSign: {
      type: Boolean,
      default: false
    },
    showHelpLink: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      showIdAustriaDialog: false,
      externalLinkId: Math.floor(Math.random() * 10000).toString()
    };
  },
  computed: {
    helpCenterUrl: function helpCenterUrl() {
      return this.$t('ident_methods.help_link.url');
    },
    swisscomIdentUrl: function swisscomIdentUrl() {
      return this.$t('ident_method.external.swisscom.link');
    }
  }
});